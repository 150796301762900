<template>
  <div class="header">
    <div class="w-auto">
      <div class="logo" @click="$router.push('/index')">
        <img class="logo-img" src="~assets/img/baasIndex/hx-logo.svg" alt />
      </div>
      <div class="menu-btn" @click="handleMenuBtn">
        <img
          v-show="isShow"
          class="menu-icon"
          src="~assets/img/baasIndex/close.svg"
          alt
        />
        <img
          v-show="!isShow"
          class="menu-icon"
          src="~assets/img/baasIndex/menu.svg"
          alt
        />
      </div>
      <div class="menus" :class="{ active: isShow }">
        <ul>
          <router-link to="/index" tag="li">首页</router-link>
          <li
            v-for="item in routeMenu"
            @click="$router.push(item.path)"
            :key="item.id"
            :class="{ activeMenu: $route.path.indexOf(item.path) != -1 }"
          >
            {{ item.name }}
          </li>
          <li>
            <a class="iovBlockchain" href="http://explorer.scaniov.com/"
              >IOV Blockchain Explorer</a
            >
          </li>
          <li class="controlBtn">
            <a
              class="controlCenter"
              @click="toControlCenter"
              id="controlBtn"
              href="javascript:;"
              >控制台</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      // menus: [
      //   {
      //     label: "首页",
      //     path: "/yellowPages",
      //   },
      //   {
      //     label: "平台概述",
      //     path: "",
      //     selector: "#Intro",
      //   },
      //   {
      //     label: "应用类目",
      //     path: "",
      //     selector: "#Category",
      //   },
      //   {
      //     label: "IOV Blockchain Explorer",
      //     path: "",
      //   },
      // ],
      routeMenu: [
        { id: 1, name: "应用场景", path: "/appScenarios" },
        { id: 2, name: "验证中心", path: "/verification" },
        { id: 3, name: "帮助与文档", path: "/helpCenter" },
      ],
      activeMenu: "",
      isShow: false,
    };
  },
  created() {},
  mounted() {},
  methods: {
    handleMenuBtn() {
      this.isShow = !this.isShow;
    },
    goAnchor(selector) {
      window.location.href = "/show/index.html" + selector;
      if (selector) {
        var anchor = document.querySelector(selector);
        console.log(window);
        window.scrollTo({
          top: anchor.offsetTop,
          behavior: "smooth",
        });
      }
    },
    // 前往控制台
    toControlCenter() {
      const screenWidth = document.body.clientWidth;
      if (screenWidth > 768) {
        console.log("pc端跳转");
        window.open("https://hx.scaniov.com", "_blank");
      } else {
        console.log("移动端跳转");
        this.$router.push("/control");
      }
    },
  },
};
</script>

<style scoped lang="less">
.header {
  .logo {
    // padding-top: 5px;
    cursor: pointer;
  }
  .logo-img {
    width: 130px;
    // height: 48px;
  }
  .w-auto {
    height: 48px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .menu-btn {
    display: none;
  }
  .menus {
    display: flex;
    align-items: center;
    ul {
      width: 740px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .activeMenu {
        color: #3d6df1;
      }
      li {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #6c757e;
        opacity: 1;
        cursor: pointer;
        .iovBlockchain {
          color: #6c757e;
        }
        &:hover,
        &:hover a {
          color: #3d6df1;
        }
        .controlCenter {
          display: inline-block;
          width: 82px;
          height: 36px;
          line-height: 36px;
          text-align: center;
          border: 1px solid rgba(61, 109, 241, 0.5);
          border-radius: 3px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #3d6df1;
          &:hover {
            background-color: #0079fe;
            color: #fff;
          }
        }
      }
    }
    .btn {
      width: 82px;
      height: 36px;
      border: 1px solid rgba(61, 109, 241, 0.5);
      border-radius: 2px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #3d6df1;
      opacity: 1;
      margin-left: 42px;
    }
  }
}

.header {
  background-color: #fff;
  box-shadow: 0 0 2px 1px #ccc;
}
@media screen and (max-width: 1200px) {
  .header {
    width: 100%;
    height: auto;
    padding: 0.533rem 0;

    position: relative;
    .w-auto {
      padding: 0 0.533rem;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .logo {
      padding-top: 2px;
    }
    .menu-btn {
      display: block;
      .menu-icon {
        width: 1.333rem;
      }
    }
    .menus {
      width: 100%;
      background-color: #fff;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      flex-direction: column;
      box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.4);
      height: 0;
      overflow: hidden;
      transition: all 0.5s;
      z-index: 9999;
      &.active {
        height: 14.2rem;
      }
      ul {
        width: 100%;
        flex-direction: column;
        padding-bottom: 0.8rem;
        li {
          font-size: 0.747rem;
          line-height: 2.133rem;
          text-align: center;
        }
        .controlBtn {
          margin-top: 15px;
        }
        #controlBtn {
          width: 95vw;
          height: 2.133rem;
          line-height: 2.133rem;
          font-size: 0.747rem;
          margin-left: 0;
          color: #fff;
          background-color: #3d6df1;
          margin: 0 auto;
          &:hover {
            opacity: 0.9;
          }
        }
      }
    }
  }
}
</style>
