<template>
  <div class="baas-index">
    <Header />

    <router-view />

    <!-- Footer -->
    <Footer />
  </div>
</template>

<script>
import Header from "../baasIndex/chilCpn/Header";

import Footer from "../baasIndex/chilCpn/Footer";

import { WOW } from "wowjs";
export default {
  name: "home",
  data() {
    return {
      wow: null,
    };
  },
  components: {
    Header,
    Footer,
  },
  created() {},
  mounted() {
    this.wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 100,
      live: true,
    });
    this.wow.init();
  },
  methods: {},
};
</script>

<style scoped lang="less">
</style>
