<template>
  <div class="footer">
    <div class="w-auto">
      <div class="left">
        <h3 class="title wow fadeInRight">联系我们</h3>
        <div class="fx-box wow fadeInRight">
          <ul>
            <li>
              <a href="http://www.cniblockchain.com/guanyu.html">关于公司</a>
            </li>
            <li>
              <a href="http://www.cniblockchain.com/zhaopin.html">加入我们</a>
            </li>
          </ul>
          <ul>
            <!-- <li>联系电话：020-89440129</li> -->
            <li>联系邮箱：pm@cniblockchain.com</li>
            <li>工作时间：周一至周五 9:00至18:00</li>
          </ul>
        </div>
        <div class="foot">
          Copyright © 2019-2030 广东中科智能区块链技术有限公司 All Rights
          Reserved 粤ICP备19156622号
          国家网信办区块链备案：粤网信备44011220826683380011号
        </div>
      </div>

      <div class="right wow fadeInLeft">
        <div class="QR-code">
          <img src="~assets/img/baasIndex/QR-code.png" alt="" />
          <p>扫码关注获取更多</p>
          <p>区块链资讯</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="less">
.footer {
  height: 357px;
  padding-top: 87px;
  padding-bottom: 30px;
  background-color: #0d1726;
  .w-auto {
    display: flex;
  }
  .title {
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    line-height: 41px;
    color: #ffffff;
    opacity: 1;
    margin-bottom: 48px;
  }
  .left {
    width: 600px;
    .fx-box {
      display: flex;
      align-self: flex-start;
    }
    ul {
      flex: 1;
      font-family: Source Han Sans CN;
      color: #ced0d3;
      li {
        width: 100%;
        font-size: 14px;
        line-height: 34px;
        a {
          color: #ced0d3;
        }
      }
    }
    .foot {
      font-size: 12px;
      font-family: Source Han Sans CN;
      line-height: 20px;
      color: #ced0d3;
      opacity: 1;
      white-space: nowrap;
      margin-top: 40px;
    }
  }

  .right {
    flex: 1;
    font-size: 14px;
    font-family: Source Han Sans CN;
    line-height: 20px;
    color: #ffffff;
    opacity: 0.8;
    position: relative;
    .QR-code {
      text-align: center;
      position: absolute;
      right: 0;
      bottom: 30px;
      img {
        margin-bottom: 9px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .footer {
    height: auto;
    padding-top: 2.32rem;
    padding-bottom: 0.8rem;
    .w-auto {
      padding: 0 0.8rem;
      flex-direction: column;
      position: relative;
    }
    .title {
      font-size: 0.84rem;
      line-height: 1.093rem;
      margin-bottom: 1.28rem;
    }
    .left {
      width: 100%;
      .fx-box {
        display: flex;
        flex-direction: column;
      }
      ul {
        font-size: 0.64rem;
        line-height: 1rem;
        li {
          width: 100%;
        }
      }
      .foot {
        font-size: 0.64rem;
        line-height: 1.067rem;
        white-space: pre-wrap;
        margin-top: 1.067rem;
        text-align: center;
      }
    }

    .right {
      font-size: 0.64rem;
      font-weight: 400;
      line-height: 1.067rem;
      position: absolute;
      bottom: auto;
      top: 0;
      right: 0.8rem;
      .QR-code {
        bottom: auto;
        top: 4rem;
        width: 6rem;
        img {
          width: 3.467rem;
          margin-bottom: 0.24rem;
        }
      }
    }
  }
}
</style>
